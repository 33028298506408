@charset "UTF-8";
:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}
@font-face {
  font-family: 'ViaplaySans';
  src: url(./fonts/viaplay_sans/woff/ViaplaySans-Light.woff) format('woff');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'ViaplaySans';
  src: url(./fonts/viaplay_sans/woff/ViaplaySans-Regular.woff) format('woff');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'ViaplaySans';
  src: url(./fonts/viaplay_sans/woff/ViaplaySans-Bold.woff) format('woff');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'ViaplaySans';
  src: url(./fonts/viaplay_sans/woff/ViaplaySans-Black.woff) format('woff');
  font-style: normal;
  font-weight: 700;
}
body {
  margin: 0;
  overflow: hidden;
  font-family: 'ViaplaySans', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@-webkit-keyframes blink-animation {
  0% {
    -webkit-background-color: #fff;
  }
  50% {
    -webkit-background-color: #ede4ff;
  }
  100% {
    -webkit-background-color: #d7bbf5;
  }
}

@keyframes blink-animation {
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ede4ff;
  }
  100% {
    background-color: #d7bbf5;
  }
}
