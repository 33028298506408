.Logo {
    background-color: inherit;
    padding: 0;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
}

.Logo img {
    height: 54px;
    width: 57px;
    position: relative;
    top: 5px;
}

.Main  {
    color: #fff;
    font-family: 'ViaplaySans', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue';
    font-size: 20px;
    font-weight: 700;
    margin: 0;
}

.SubMain  {
    color: #fff;
    font-family: 'ViaplaySans', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue';
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}