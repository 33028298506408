.error {
  margin-top: 100px;
  color: #1d1d27;
  text-align: center;
}

.error p {
  line-height: 2.5rem;
  margin: 5px 0;
}
